import React, { useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { Button, Pane } from 'evergreen-ui';
import { FiLogOut } from "react-icons/fi";
import { FaMapMarkedAlt, FaSuitcase, FaEdit, FaUserEdit, FaReceipt } from 'react-icons/fa'; // Importing icons
import './AdminMenu.css';

function AdminMenu() {
  const location = useLocation();
  const navigate = useNavigate();
  const token = location.state?.token;

  useEffect(() => {
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        if (decodedToken.role !== 'admin') {
          navigate('/error404');
        }
      } catch (error) {
        console.error('Invalid token:', error);
        navigate('/error404');
      }
    } else {
      navigate('/error404');
    }
  }, [token, navigate]);

  return (
    <Pane className="admin-container" display="flex" justifyContent="center" alignItems="center" height="90vh">
      <Pane className="admin-menu" display="flex" flexDirection="column" gap={16}>
        <Link to='/admin-destination' style={{ textDecoration: 'none' }}>
          <Button appearance="primary" intent="success" className='admin_btn' display="flex" flexDirection="column" alignItems="center">
            <FaMapMarkedAlt size={24} /> {/* Icon */}
            <span>New Destinations</span> {/* Text */}
          </Button>
        </Link>
        <Link to='/admin-tour' style={{ textDecoration: 'none' }}>
          <Button appearance="primary" intent="success" className='admin_btn' display="flex" flexDirection="column" alignItems="center">
            <FaSuitcase size={24} />
            <span>New Tours</span>
          </Button>
        </Link>
        <Link to='/destination-edit' style={{ textDecoration: 'none' }}>
          <Button appearance="primary" intent="success" className='admin_btn' display="flex" flexDirection="column" alignItems="center">
            <FaEdit size={24} />
            <span>Edit Destinations</span>
          </Button>
        </Link>
        <Link to='/tour-edit' style={{ textDecoration: 'none' }}>
          <Button appearance="primary" intent="success" className='admin_btn' display="flex" flexDirection="column" alignItems="center">
            <FaEdit size={24} />
            <span>Edit Tours</span>
          </Button>
        </Link>
        <Link to='/user-edit' style={{ textDecoration: 'none' }}>
          <Button appearance="primary" intent="success" className='admin_btn' display="flex" flexDirection="column" alignItems="center">
            <FaUserEdit size={24} />
            <span>Edit Users</span>
          </Button>
        </Link>
        <Link to='/booked-receipts' style={{ textDecoration: 'none' }}>
          <Button appearance="primary" intent="success" className='admin_btn' display="flex" flexDirection="column" alignItems="center">
            <FaReceipt size={24} />
            <span>Receipts</span>
          </Button>
        </Link>
        <Link to="/login" style={{ textDecoration: 'none' }} onClick={() => {
        localStorage.removeItem('token'); // Remove token from storage
         window.location.href = "/login"; // Redirect to login page
  }}>
          <Button appearance="primary" intent="success" className='admin_btn' display="flex" flexDirection="column" alignItems="center">
            <FiLogOut size={24} />
            <span>Log Out</span>
          </Button>
        </Link>
      </Pane>
    </Pane>
  );
}

export default AdminMenu;
