import React, { useState, useEffect } from 'react';
import { TextInputField, Textarea, FilePicker, Pane, Table, Dialog, toaster, Button } from 'evergreen-ui';
import './DestinationEdit.css';

function DestinationEdit() {
  const [destinations, setDestinations] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [isDialogShown, setIsDialogShown] = useState(false);

  useEffect(() => {
    fetchDestinations();
  }, []);

  const fetchDestinations = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/destinations`);
      if (!response.ok) throw new Error('Failed to fetch destinations');
      const data = await response.json();
      setDestinations(data);
    } catch (error) {
      console.error('Error fetching destinations:', error);
      toaster.danger('Failed to fetch destinations');
    }
  };

  const handleEdit = (destination) => {
    setSelectedDestination(destination);
    setIsDialogShown(true);
  };

  const handleDelete = async (id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/destinations/${id}`, {
        method: 'DELETE'
      });
      if (!response.ok) throw new Error('Failed to delete destination');
      setDestinations(destinations.filter(dest => dest.id !== id));
      toaster.success('Destination deleted successfully');
    } catch (error) {
      console.error('Error deleting destination:', error);
      toaster.danger('Failed to delete destination');
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedDestination({ ...selectedDestination, [name]: value });
  };

  const handleFileChange = (files, name) => {
    setSelectedDestination({ ...selectedDestination, [name]: files[0] });
  };

  

  const handleUpdate = async () => {
    const uploadData = new FormData();
    uploadData.append('destination_name', selectedDestination.destination_name);
    uploadData.append('destination_title1', selectedDestination.destination_title1);
    uploadData.append('description1', selectedDestination.description1);
    uploadData.append('destination_title2', selectedDestination.destination_title2);
    uploadData.append('description2', selectedDestination.description2);
    if (selectedDestination.image_preview) uploadData.append('image_preview', selectedDestination.image_preview);
    if (selectedDestination.image1) uploadData.append('image1', selectedDestination.image1);
    if (selectedDestination.image2) uploadData.append('image2', selectedDestination.image2);
    if (selectedDestination.image3) uploadData.append('image3', selectedDestination.image3);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/destinations/${selectedDestination.id}`, {
        method: 'PUT',
        body: uploadData
      });
      if (!response.ok) throw new Error('Failed to update destination');
      const updatedDestination = await response.json();
      setDestinations(destinations.map(dest => (dest.id === updatedDestination.id ? updatedDestination : dest)));
      setIsDialogShown(false);
      toaster.success('Destination updated successfully');
    } catch (error) {
      console.error('Error updating destination:', error);
      toaster.danger('Failed to update destination');
    }
  };

  return (
    <Pane className="edit-container" padding={16}>
      <Table>
        <Table.Head>
          <Table.TextHeaderCell>Destination Name</Table.TextHeaderCell>
          <Table.TextHeaderCell>Actions</Table.TextHeaderCell>
        </Table.Head>
        <Table.Body>
          {destinations.map(destination => (
            <Table.Row key={destination.id}>
              <Table.TextCell>{destination.destination_name}</Table.TextCell>
              <Table.Cell>
                <Button appearance='primary' onClick={() => handleEdit(destination)} marginRight={8}>Edit</Button>
                <Button appearance='primary' intent='danger' onClick={() => handleDelete(destination.id)}>Delete</Button>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      <Dialog
        isShown={isDialogShown}
        title="Edit Destination"
        width="90%"
        height="85%"
        onCloseComplete={() => setIsDialogShown(false)}
        confirmLabel="Save"
        onConfirm={handleUpdate}
      >
        {selectedDestination && (
          <form>
            <TextInputField label="Destination Name" name="destination_name" value={selectedDestination.destination_name} onChange={handleInputChange} required />
            <TextInputField label="Destination Title 1" name="destination_title1" value={selectedDestination.destination_title1} onChange={handleInputChange} required />
            <Textarea label="Description 1" name="description1" value={selectedDestination.description1} onChange={handleInputChange} required />
            <TextInputField label="Destination Title 2" name="destination_title2" value={selectedDestination.destination_title2} onChange={handleInputChange} required />
            <Textarea label="Description 2" name="description2" value={selectedDestination.description2} onChange={handleInputChange} required />
            
            {selectedDestination && (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {selectedDestination.image_preview && (
                  <img 
                    src={`${process.env.REACT_APP_BASE_URL}/${selectedDestination.image_preview}`} 
                    alt="Preview" 
                    style={{ width: '250px', height: '250px', objectFit: 'cover', marginBottom: '10px' }} 
                  />
                )}
                <FilePicker
                  label="Replace Image Preview"
                  name="image_preview"
                  onChange={(files) => handleFileChange(files, 'image_preview')}
                  accept="image/*"
                />
              </div>
            )}

            {['image1', 'image2', 'image3'].map((imageKey) => (
              <div key={imageKey} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10px' }}>
                {selectedDestination[imageKey] && (
                  <img 
                    src={`${process.env.REACT_APP_BASE_URL}/${selectedDestination[imageKey]}`} 
                    alt={imageKey} 
                    style={{ width: '250px', height: '250px', objectFit: 'cover', marginBottom: '10px' }} 
                  />
                )}
                <FilePicker
                  label={`Replace ${imageKey}`}
                  name={imageKey}
                  onChange={(files) => handleFileChange(files, imageKey)}
                  accept="image/*"
                />
              </div>
            ))}
          </form>
        )}
      </Dialog>
    </Pane>
  );
}

export default DestinationEdit;
