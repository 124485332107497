import React, { useState, useEffect } from 'react';
import { FaEye } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { Spinner, toaster } from 'evergreen-ui'; // Import Evergreen Toaster
import './Login.css';
import AOS from "aos";
import "aos/dist/aos.css";

const Login = () => {
  const navigate = useNavigate();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false); // State for button loading

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  }, []);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    if (!validateEmail(formData.email)) {
      toaster.danger('Invalid email address');
      return false;
    }
    if (formData.password.length < 6) {
      toaster.danger('Password must be at least 6 characters long');
      return false;
    }
    return true;
  };

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }
    
    setLoading(true); // Set loading to true when submitting

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/users/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to log in');
      }

      const data = await response.json();
      localStorage.setItem('token', data.token);
      const user = jwtDecode(data.token);

      if (user.role === 'admin') {
        navigate('/dashboard-admin', { state: { token: data.token } });
      } else {
        navigate('/dashboard-user', { state: { token: data.token } });
      }

      toaster.success('Logged in successfully');
      setFormData({ email: '', password: '' });
    } catch (err) {
      console.error('Error:', err);
      toaster.danger(err.message);
    } finally {
      setLoading(false); // Reset loading state after request completes
    }
  };

  return (
    <div data-aos="zoom-in" className="login-form">
      <toaster position="top-right" />
      <p className='title-secondary' style={{ textAlign: "center" }}>Login</p>
      <form className='loginform-sect' onSubmit={handleSubmit}>
        <div className="form-group">
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
          />
          <label>Email address*</label>
        </div>
        <div className="form-group">
          <div className="password-input">
            <input
              type={passwordVisible ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
            <label>Password*</label>
            <FaEye onClick={togglePasswordVisibility} className="eye-icon" />
          </div>
        </div>
        <div className="forgot-password">
          <Link className='l-link' to='/forgot-password'><span>Forgot password?</span></Link>
        </div>
        <button type="submit" className="btn-continue" disabled={loading} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          {loading ? <Spinner size={16} color="orange" /> : 'Continue'}
        </button>
      </form>
      <div className="signup-link">
        <span>Don't have an account? </span>
        <Link className='l-link' to='/register'><span>Sign up</span></Link>
      </div>
    </div>
  );
};

export default Login;