import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import './Destination_info.css';
import AOS from "aos";
import "aos/dist/aos.css";
import Newsletter from './Newsletter';

function Destination_info() {
  const [searchParams] = useSearchParams();
  const destination_id = searchParams.get("id"); // Get the destination ID from query params
  const [destination, setDestination] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    const fetchDestination = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/destinations/${destination_id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch destination details');
        }
        const data = await response.json();
        setDestination(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    if (destination_id) {
      fetchDestination();
    }
  }, [destination_id]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!destination) {
    return <div>No destination details available.</div>;
  }

  const splitParagraphs = (text) => {
    return text ? text.split('\n').map((para, index) => <p key={index} className='txt_cont'>{para}</p>) : null;
  };

  return (
    <div className='destinfo-container'>
      <div className="banner-destinfo">
        <div className="banner-sectinfo">
          <p className='banner-title'>{destination.destination_name}</p>
        </div>
      </div>

      <div data-aos="zoom-in" className="destinfo-content">
        <p className='title-primary info-align'>{destination.destination_title1}</p>
        {splitParagraphs(destination.description1)}

        <div className="destinfo_box">       
          <div className="destinfo_gallery" key={destination.destination_id}>
            <img src={`${process.env.REACT_APP_BASE_URL}/${destination.image1}`} alt={destination.destination_name} className='dest_img' />
            <img src={`${process.env.REACT_APP_BASE_URL}/${destination.image2}`} alt={destination.destination_name} className='dest_img' />
            <img src={`${process.env.REACT_APP_BASE_URL}/${destination.image3}`} alt={destination.destination_name} className='dest_img' />
          </div>      
        </div>

        <p className='title-primary info-align'>{destination.destination_title2}</p>
        {splitParagraphs(destination.description2)}
      </div>
      
      <Newsletter />
    </div>
  );
}

export default Destination_info;
