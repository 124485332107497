import React, { useState, useEffect } from 'react';
import { TextInputField, Textarea, FilePicker, Button, Pane, toaster, Heading, SelectMenu, Spinner} from 'evergreen-ui';
import './AdminDestination.css';

function Admin_destination() {
  const [destinations, setDestinations] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState('Select a country');
  const [loading, setLoading] = useState(false);
  
  const [formData, setFormData] = useState({
    destination_name: '',
    destination_title1: '',
    description1: '',
    destination_title2: '',
    description2: '',
    country: '', // Add country to formData
    image_preview: null,
    image1: null,
    image2: null,
    image3: null
  });

  useEffect(() => {
    const fetchDestinations = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/destinations`);
        if (!response.ok) {
          throw new Error('Failed to fetch destinations');
        }
        const data = await response.json();
        setDestinations(data);
      } catch (error) {
        console.error('Error fetching destinations:', error);
      }
    };

    fetchDestinations();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (files, name) => {
    const file = files[0];
    const maxSize = 1 * 1024 * 1024; // 1MB in bytes
  
    if (file && file.size > maxSize) {
      toaster.danger('File size exceeds 1MB. Please upload a smaller file.');
      return;
    }
  
    setFormData({
      ...formData,
      [name]: file
    });
  };

  const clearForm = () => {
    setFormData({
      destination_name: '',
      destination_title1: '',
      description1: '',
      destination_title2: '',
      description2: '',
      country: '',
      image_preview: null,
      image1: null,
      image2: null,
      image3: null
    });
    
    document.querySelectorAll('input[type="file"]').forEach(input => (input.value = ""));
    setSelectedCountry('Select a country');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const uploadData = new FormData();
    uploadData.append('destination_name', formData.destination_name);
    uploadData.append('destination_title1', formData.destination_title1);
    uploadData.append('description1', formData.description1);
    uploadData.append('destination_title2', formData.destination_title2);
    uploadData.append('description2', formData.description2);
    uploadData.append('country', formData.country); // Append country to the form data
    uploadData.append('image_preview', formData.image_preview);
    uploadData.append('image1', formData.image1);
    uploadData.append('image2', formData.image2);
    uploadData.append('image3', formData.image3);

    try {
      const response = await fetch(`${process.env.REACT_APP_BASE_URL}/destinations`, {
        method: 'POST',
        body: uploadData
      });
      if (!response.ok) {
        throw new Error('Failed to upload destination');
      }
      const newDestination = await response.json();
      setDestinations([...destinations, newDestination]);

      toaster.success('Destination uploaded successfully!');
      clearForm();
    } catch (error) {
      console.error('Error uploading destination:', error);
      toaster.danger('Failed to upload destination');
    }
  };

  return (
    <Pane className="dest-container" padding={16} borderRadius={8} elevation={2} background="tint2">
      <Heading size={600} marginBottom={16}>Upload New Destination List</Heading>
      <form onSubmit={handleSubmit} className="upload-form">
        {/* Country selection using SelectMenu */}
        <SelectMenu
          title="Select Country"
          options={['Kenya', 'Uganda', 'Tanzania'].map((label) => ({ label, value: label }))}
          selected={selectedCountry}
          onSelect={(item) => {
            setSelectedCountry(item.value);
            setFormData({ ...formData, country: item.value });
          }}
        >
          <Button style={{
            color: '#000', 
            backgroundColor: '#fff', 
            border: '1px solid #ccc', 
            transition: 'background-color 0.3s ease',
          }}>
            {selectedCountry || 'Select a country (e.g., Kenya)'}
          </Button>
        </SelectMenu>
        
        <TextInputField
          label="Destination Name"
          name="destination_name"
          value={formData.destination_name}
          onChange={handleInputChange}
          placeholder="Enter the full destination name (e.g., 'Mount Kilimanjaro National Park')"
          required
        />
        <TextInputField
          label="Main Attraction Title"
          name="destination_title1"
          value={formData.destination_title1}
          onChange={handleInputChange}
          placeholder="Enter the title for the first attraction (e.g., 'Hiking Trails')"
          required
        />
        <Textarea
          label="Description of Main Attraction"
          name="description1"
          value={formData.description1}
          onChange={handleInputChange}
          placeholder="Provide a detailed description of the first attraction..."
          height={150}
          required
        />
        <TextInputField
          label="Additional Attraction Title"
          name="destination_title2"
          value={formData.destination_title2}
          onChange={handleInputChange}
          placeholder="Enter the title for the second attraction"
          required
        />
        <Textarea
          label="Description of Additional Attraction"
          name="description2"
          value={formData.description2}
          onChange={handleInputChange}
          placeholder="Provide a detailed description of the second attraction..."
          height={150}
          required
        />
        <FilePicker
          label="Image Preview"
          name="image_preview"
          onChange={(files) => handleFileChange(files, 'image_preview')}
          required
          accept="image/*"
          description="Select the main preview image (JPEG, PNG) for this destination."
        />
        <FilePicker
          label="Additional Image 1"
          name="image1"
          onChange={(files) => handleFileChange(files, 'image1')}
          required
          accept="image/*"
          description="Select the first additional image (JPEG, PNG) for this destination."
        />
        <FilePicker
          label="Additional Image 2"
          name="image2"
          onChange={(files) => handleFileChange(files, 'image2')}
          required
          accept="image/*"
          description="Select the second additional image (JPEG, PNG) for this destination."
        />
        <FilePicker
          label="Additional Image 3"
          name="image3"
          onChange={(files) => handleFileChange(files, 'image3')}
          required
          accept="image/*"
          description="Select the third additional image (JPEG, PNG) for this destination."
        />
        <Button appearance="primary" type="submit" marginTop={16} disabled={loading}>
          {loading ? <Spinner size={16} /> : 'Upload Destination'}
        </Button>
      </form>
    </Pane>
  );
}

export default Admin_destination;
